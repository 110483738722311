import React, { useState } from "react"
import useTimer from "src/utils/hooks/useTimer"
import Timer from "src/components/test/question/components/timer"
import _ from "lodash"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "src/utils/hooks/useFormatMessage"
import notifications from "src/utils/notifications"
import Button from "src/components/base/buttons/regular"
import { PauseIcon, PlayIcon } from "@heroicons/react/outline"
import Sound from "react-sound"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const m = defineMessages({
  submitButtonText: "Submit",
  timerHasEndedNotificationTitle: "The time has ended",
  timerHasEndedNotificationMessage: "You are redirected to a new question",
  singleAudioQuestionHint: "What is described in the audio?",
  singleAudioQuestionHint2: "Click play to start listening.",
  playAudioButtonTooltip: "Click to play again",
  NONE_OF_THE_ABOVE: "None of the above",
  play: "Play",
  pause: "Pause",
})

type Props = {
  question?: string
  secondaryQuestion?: string
  hint?: string
  instruction?: string
  audio: string
  suggestions: string[]
  answer: (answer: string, time: number) => void
}

const MAX_TIMER_DURATION_IN_SECONDS = 41
const MAX_PLAY_COUNT = 3

const shuffle = _.memoize(suggestions => {
  if (!suggestions) return []
  return _.shuffle(suggestions)
})

export const MultipleChoiceAudioQuestion: React.FC<Props> = props => {
  const { question, secondaryQuestion, hint, instruction, audio, suggestions, answer } = props

  const [selected, setSelected] = useState("")

  const [isPlayingAudio, setIsPlayingAudio] = useState(false)
  const [timesPlayed, setTimesPlayed] = useState(0)

  const t = useFormatMessage()
  const { seconds, increase } = useTimer()

  const maxPlaysReached = timesPlayed >= MAX_PLAY_COUNT

  const play = () => {
    if (timesPlayed > 0) {
      increase(35)
    }
    setTimesPlayed(timesPlayed + 1)
    setIsPlayingAudio(true)
  }

  const stop = () => {
    setIsPlayingAudio(false)
  }

  const toggleAudio = () => {
    if (isPlayingAudio) {
      stop()
    } else {
      if (!maxPlaysReached) {
        play()
      }
    }
  }

  const { regular } = useThemeColors()

  const shuffledSuggestions = shuffle(suggestions)

  return (
    <div className={`flex flex-col items-center justify-center`}>
      <div className="flex w-full relative">
        <div className={`w-full flex absolute ${seconds < 31 ? "visible" : "invisible"}`}>
          <div className="flex flex-1" />
          <Timer
            isPlaying={seconds < 31}
            onTimerEnded={() => {
              notifications.info(t(m.timerHasEndedNotificationTitle), t(m.timerHasEndedNotificationMessage))
              answer(selected, MAX_TIMER_DURATION_IN_SECONDS * 1000)
            }}
          />
        </div>
      </div>
      <div className="flex flex-col items-center space-y-1">
        {question && <span className="font-semibold text-lg">{question}</span>}
        {secondaryQuestion && <span className="font-medium">{secondaryQuestion}</span>}
        {hint && <span className="text-sm">{hint}</span>}
        {instruction && <span className="text-sm">{instruction}</span>}
      </div>
      <div className={`flex space-x-2 my-8 ${maxPlaysReached && !isPlayingAudio ? "opacity-50" : "opacity-100"}`}>
        <Button className="text-sm" onClick={() => toggleAudio()}>
          {isPlayingAudio ? t(m.pause) : t(m.play)}
          {isPlayingAudio ? <PauseIcon className={`h-5 w-5 ml-2 text-${regular}`} /> : <PlayIcon className="h-5 w-5 ml-2 text-gray-500" />}
          <Sound url={audio} autoLoad={true} playStatus={isPlayingAudio ? "PLAYING" : "STOPPED"} onFinishedPlaying={() => stop()} />
        </Button>
      </div>

      <div className="flex pb-4 items-center justify-center flex-wrap">
        {shuffledSuggestions.map(suggestion => (
          <div
            className={`m-1 p-2 px-4 border-2 ${
              selected === suggestion ? `border-${regular}` : "border-gray-400"
            } hover:border-${regular} cursor-pointer rounded overflow-hidden`}
            key={suggestion}
            onClick={() => {
              setSelected(suggestion)
            }}
          >
            {suggestion === "NONE_OF_THE_ABOVE" ? t(m.NONE_OF_THE_ABOVE) : suggestion}
          </div>
        ))}
      </div>
      {selected && (
        <Button
          primary
          className="text-sm"
          onClick={() => {
            answer(selected, (MAX_TIMER_DURATION_IN_SECONDS - seconds) * 1000)
          }}
        >
          {t(m.submitButtonText)}
        </Button>
      )}
    </div>
  )
}
