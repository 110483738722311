import { defineMessages } from "gatsby-plugin-intl"

export default defineMessages({
  completedIn: "Completed in",
  minutes: "min",
  congratulations: "Congratulations! You finished the test.",
  Positives: "Positives",
  Improvements: "Improvements",
  Duration: "Duration",
  MyScore: "Your level",
  Reading: "Reading",
  Description: "General",
  Professional: "Professional",
  Structure: "Structure points you can work on:",
  StructureTitle: "Structure",
  Writing: "Writing",
  Listening: "Listening",
  Speaking: "Speaking",
  A0Congratulations: "You have an A0 level. You do not yet have any notions of this langauge!",
  A050Congratulations: "You have an A0_50 level. You are taking your first steps in this langauge.",
  A075Congratulations: "You have an A0_75 level. You are taking your first steps in this langauge.",
  A1Congratulations: "Congratulations! You have an A1 level. You already have notions of this language!",
  A2Congratulations: "Congratulations! You have an A2 level. You already can use the language in various elementary situations!",
  B1Congratulations: "Congratulations! You have an B1 level. You are an independent user of the language!",
  B2Congratulations: "Congratulations! You have an B2 level. You can already have enriching and targeted exchanges in the language!",
  C1Congratulations: "Congratulations! You have an C1 level. You are close to becoming a native in the language!",
  C2Congratulations: "Congratulations! You have an C2 level. You are an expert of the language!",
  A0Description: "First steps",
  A1Description: "Breakthrough",
  A0DescriptionPos: "You do not understand English and cannot speak it yet.",
  A0DescriptionImp: "You now can work on understanding basic words and recognizing the language when you hear or see it.",
  A050DescriptionPos: "You can understand a very limited number of basic words, sometimes recognize English when you see or hear it.",
  A050DescriptionImp:
    "You now can work on understanding familiar and everyday expressions, introducing yourself and others, asking questions about personal details, interacting in a simple way.",
  A1DescriptionPos:
    "You can understand and use familiar everyday expressions, introduce yourself and others, ask and answer questions about personal details, interact in a simple way provided the other person talks slowly and clearly and is prepared to help",
  A1DescriptionImp:
    "You now need to work on expanding the areas you are familiar with, describing elements of your daily life with more details, asking simple questions to your peers ",
  A2DescriptionPos:
    "You can understand sentences and frequently used expressions related to areas you are directly in contact with, describe in simple terms aspects of your background, immediate environment and matters in areas of immediate need, exchange on familiar matters and execute simple tasks",
  A2DescriptionImp:
    "You now need to work on understanding topics you are not familiar with, dealing with situations that are not typical to your direct environment, describing your hopes, ambitions, justifying your choices",
  B1DescriptionPos:
    "You can understand the main points of a conversation on familiar matters regularly encountered in work, leisure, etc, deal with most situations likely to arise whilst travelling in an area where the language is spoken, describe experiences and events, dreams, hopes & ambitions, give reasons and explanations for opinions and plans",
  B1DescriptionImp:
    "You now need to work on understanding more complex ideas and topics (concrete and abstract), improving your fluency and spontaneity, giving your viewpoint by explaining in details the advantages and disadvantages of various situations",
  B2DescriptionPos:
    "You can understand the main ideas of complex text/conversation on both concrete and abstract topics, including technical discussions in your field of specialisation, interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible, explain a viewpoint on an  issue giving the advantages and disadvantages of various options",
  B2DescriptionImp:
    "You now need to work on understanding a wider range of demanding and more complex topics, adapting your choice of words and structures according to the context you are in",
  C1DescriptionPos:
    "You can understand a wide range of demanding, longer texts, and recognise implicit meaning, express yourself fluently and spontaneously without much obvious searching for expressions, use language flexibly and effectively for social, academic and professional purposes, produce clear, well-structured, detailed text on complex subjects, showing controlled use of organisational patterns, connectors and cohesive devices.",
  C1DescriptionImp:
    "You now need to work on appreciating the different subtelties of the language, using the language efficiently enough to manage complex negociating or tense situations",
  C2DescriptionPos:
    "You can understand with ease virtually everything heard or read, summarise information from different spoken and written sources, reconstructing arguments and accounts in a coherent presentation, express yourself spontaneously, very fluently and precisely, differentiating finer shades of meaning even in more complex situations",
  C2DescriptionImp: "You now need to work on:, making sure natives can mistake you for one of them!",
  A2Description: "Waystage",
  B1Description: "Threshold",
  B2Description: "Vantage",
  C1Description: "Effective Proficiency",
  C2Description: "Mastery",
  A0ProfessionalPos:
    "You can describe you job very simply, make simple demands at work, understand familiar elements and/or words in your working environment",
  A0ProfessionalImp:
    "You now need to work on expanding the words you use at work everyday, describing your tasks more precisely, asking simple questions to your colleagues ",
  A050ProfessionalPos:
    "You can describe you job very simply, make simple demands at work, understand familiar elements and/or words in your working environment",
  A050ProfessionalImp:
    "You now need to work on expanding the words you use at work everyday, describing your tasks more precisely, asking simple questions to your colleagues ",
  A1ProfessionalPos:
    "You can describe you job very simply, make simple demands at work, understand familiar elements and/or words in your working environment",
  A1ProfessionalImp:
    "You now need to work on expanding the words you use at work everyday, describing your tasks more precisely, asking simple questions to your colleagues ",
  A2ProfessionalPos:
    "You can explain your tasks and duties, make demands about familiar topics at work, understand basic questions and answer them accordingly",
  A2ProfessionalImp:
    "You now need to work on handling situations you are not familiar with in your working environment, being independent in order to handle tasks on your own, make demands and questions that are specific enough to avoid confusions",
  B1ProfessionalPos:
    "You can handle simple situations with confidence and professionalism, function in the target language in your working environment, make clear demands on many different topics in a working environment, understand most of conversations and demands at work",
  B1ProfessionalImp:
    "You now need to work on giving your opinion and structuring your argumentation in professional exchanges, being as precise as possible to get the right nuances, understanding elements you are not familiar with at all",
  B2ProfessionalPos:
    "You can participate actively in most of the conversations and meetings, discuss and give opinions on professional matters, understand more complex topics that you are not familiar with at work",
  B2ProfessionalImp:
    "You now need to work on being confident enough in the target language to lead any activity, convincing people and making complex suggestions, challenging yourself to go even further!",
  C1ProfessionalPos:
    "You can participate in any conversation with great ease, even if it is not your specialty, lead meetings and debate, use subtle nuances to persuade people",
  C1ProfessionalImp:
    "You now need to work on acting in your professional environment as a native would, doing things you are not used to do, challenging yourself!",
  C2ProfessionalPos: "You can act in a professional environment as a native would",
  C2ProfessionalImp: "You now need to work on keeping on learning new things!",
  ProtectasA0Congratulations:
    "You have a level situated between A0 and A1 within the Protectas language framework. You are invited to improve your knowledge base of the language for use in your professional context. The required skills at Protectas allowing you to interact in the workplace are not yet acquired.",
  ProtectasA1Congratulations:
    "You have a level situated between A1 and A2 within the Protectas language framework.  You master some fundementals allowing you to partially communicate with colleagues and external parties. You still lack knowledge relating to professional contexts in hospitality and security.",
  ProtectasA2Congratulations:
    "You have a level situated between A2 and B1 within the Protectas language framework. You enjoy a certain degree of autonomy in professional contexts related to hospitality and security. You still lack some knowledge allowing you to communicate more transversally within the scope of your work at Protectas.",
  ProtectasB1Congratulations:
    "You have a level situated between B1 and B2 within the Protectas language framework. You are quite autonomous when communicating in a professional context whether it be in hospitality or security. You are in a position to further progress so as to be more targeted in your communication with various types of profiles. You have the necessary skills to function within Protectas.",
  ProtectasB2Congratulations:
    "You have a level situated above B2 within the Protectas language framework. You are fully autonomous and in a position to communicate clearly on professional and non-professional topics. You have the proven capacity to interact rather seamlessly within Protectas.",
})
