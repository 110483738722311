import _ from "lodash"
import React, { useState } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import { useFetching } from "../../../../../utils/hooks/useFetch"
import { fetchLevelEvaluation } from "../../../../../store/levelEvaluation/actions"
import { levelEvaluationSelector } from "../../../../../store/levelEvaluation/selectors"
import { getLanguageName, getLanguageCode } from "../../../../../utils/language"
import Loader from "../../../../base/loaders/loader"
import LevelScale from "./components/levelScale"
import messages from "./messages"
import logo from "../../../../../images/logos/logo.png"
import useFormatMessage from "../../../../../utils/hooks/useFormatMessage"
import { FaPrint } from "react-icons/fa"
import SelectPrint from "./components/selectPrint"
import { EvaluationType } from "src/utils/evaluation-type-utils"
import { classNames } from "src/utils/classnames"

const Final = ({ levelEvaluationUUId }) => {
  const [selectPrintModalVisible, setSelectPrintModalVisible] = useState(false)

  useFetching(fetchLevelEvaluation(levelEvaluationUUId), levelEvaluationUUId)
  const { result, loading } = useSelector(levelEvaluationSelector(levelEvaluationUUId))
  let person
  let languageTested
  let languageLevel
  let languagePercentage

  if (result) {
    person = result.person
    languageTested = getLanguageCode(result.evaluatedlanguage.toUpperCase())
    languageLevel = result.finallevel
    languagePercentage = Math.round((result.finalpercentage * 100 + Number.EPSILON) * 100) / 100
  }

  const formatMessage = useFormatMessage()

  if (!result) {
    return null
  }

  let subjectLevels
  Object.keys(result.levelsindetail).forEach(key => {
    const value = result.levelsindetail[key]
    if (typeof value === "string" && /^[A-Z][0-9] \([0-9]*\)$/.test(value)) {
      subjectLevels = {
        ...subjectLevels,
        [key]: {
          majorLevel: _.first(value.split(" ")),
          minorLevel: _.last(value.split(" ")).replace(/[{()}]/g, ""),
        },
      }
    }
  })

  const duration = Math.ceil(result.totalansweringtime / 60)
  const name = person ? `${person.firstname} ${person.lastname}` : "Anonymous"
  const parts = languageLevel.split(" ")
  const majorLevel = _.first(parts)
  const minorLevel = parts.length > 1 ? _.last(parts) : null

  const formatLevelMessage = (message, pi = "", majLevel, minLevel) => {
    // We return different types of messages if the report is an EASY one.
    // We don't always return in this statement, because if nothing is matched,
    // we still want to fallback on the default translations.
    if (result?.letype?.code === EvaluationType.EASY) {
      if (message === "Congratulations") {
        switch (majorLevel) {
          case "A0":
            return formatMessage(messages["ProtectasA0Congratulations"])
          case "A1":
            return formatMessage(messages["ProtectasA1Congratulations"])
          case "A2":
            return formatMessage(messages["ProtectasA2Congratulations"])
          case "B1":
            return formatMessage(messages["ProtectasB1Congratulations"])
          case "B2":
            return formatMessage(messages["ProtectasB2Congratulations"])
        }
      }
    }

    if (majLevel) {
      if (majLevel === "A0" && (minLevel === "50" || minLevel === "75")) {
        return formatMessage(messages[`A050${message}${pi}`])
      }
      return formatMessage(messages[`${majLevel}${message}${pi}`])
    }

    if (majorLevel === "A0" && minorLevel === "(50)" && `A050${message}${pi}` in messages) {
      return formatMessage(messages[`A050${message}${pi}`])
    }
    if (majorLevel === "A0" && minorLevel === "(75)" && `A075${message}${pi}` in messages) {
      return formatMessage(messages[`A075${message}${pi}`])
    }
    if (majorLevel === "A0" && minorLevel === "(75)" && `A050${message}${pi}` in messages) {
      return formatMessage(messages[`A050${message}${pi}`])
    }

    return formatMessage(messages[`${majorLevel}${message}${pi}`])
  }

  const openSelectPrintModal = () => {
    setSelectPrintModalVisible(true)
  }
  const closeSelectPrintModal = () => {
    setSelectPrintModalVisible(false)
  }

  const isEasy = result?.letype?.code === EvaluationType.EASY

  return (
    <>
      {loading && <Loader />}
      <div id="section-to-print">
        <div className="flex mb-6 ">
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-baseline">
              <div className="text-4xl font-semibold text-gray-800">{majorLevel}</div>
              {minorLevel && <p className=" text-lg font-semibold text-gray-500 mt-4 ">({minorLevel?.replace(/[{()}]/g, "")})</p>}
            </div>
            <p className="font-semibold text-gray-500 uppercase">{formatLevelMessage("Description")}</p>
          </div>
          <div className="flex flex-1 justify-center items-start"></div>
          <div className="flex-col items-end">
            <div className="text-xl font-semibold text-gray-800 text-right">{name}</div>
            <div className="text-base font-semibold text-gray-600 text-right">{getLanguageName(languageTested)}</div>
            <div className="text-sm font-semibold text-gray-500 text-right mt-1">{moment(result.finishedtimestamp).format("LL")}</div>
          </div>
        </div>
        <p className={classNames("text-gray-800 w-full text-center mb-4", isEasy ? "text-medium font-medium pt-8" : "text-xl font-semibold")}>
          {formatLevelMessage("Congratulations")}
        </p>
        {isEasy && (
          <div className="flex flex-col items-center justify-center pb-8 pt-4 text-gray-700">
            <p>
              <span className="font-semibold">David Michel</span> | Managing Director Atolo Switzerland
            </p>
            <p>
              <span className="font-semibold">Jean Schacht</span> | HR Director Protectas
            </p>
          </div>
        )}

        <div className="text-base font-semibold text-gray-600 w-full text-center mb-2"></div>
        <div className="text-xl font-semibold text-gray-600 w-full hidden-print">{formatMessage(messages.MyScore)}</div>
        <LevelScale
          isSimple={result?.letype?.code === EvaluationType.EASY}
          currentLevel={languageLevel}
          majorLevel={majorLevel}
          minorLevel={minorLevel}
          languagePercentage={languagePercentage}
          className="mt-8"
        />
        {isEasy && <div className="h-12" />}
        {/* GENERAL */}
        {!!subjectLevels.sectionGENE && (
          <>
            <div className="text-xl font-semibold text-gray-800 w-full mb-2 mt-12">
              {formatMessage(messages.Description)} {subjectLevels.sectionGENE.majorLevel}
              <span className="text-base font-semibold text-gray-500">({subjectLevels.sectionGENE.minorLevel})</span>
            </div>
            <div className="ml-3 text-xl font-normal text-gray-700 w-full mb-2">{formatMessage(messages.Positives)}</div>
            <div className="ml-3 text-sm text-gray-800 mb-2">
              {formatLevelMessage("Description", "Pos", subjectLevels.sectionGENE.majorLevel, subjectLevels.sectionGENE.minorLevel)}
            </div>
            <div className="ml-3 text-xl font-normal text-gray-700 w-full mb-2">{formatMessage(messages.Improvements)}</div>
            <div className="ml-3 text-sm text-gray-800 mb-2">
              {formatLevelMessage("Description", "Imp", subjectLevels.sectionGENE.majorLevel, subjectLevels.sectionGENE.minorLevel)}
            </div>
          </>
        )}
        {/* PROFESSIONAL */}
        {!!subjectLevels.sectionBUSI && (
          <>
            <div className="text-xl font-semibold text-gray-800 w-full mb-2 mt-12">
              {formatMessage(messages.Professional)} {subjectLevels.sectionBUSI.majorLevel}
              <span className="text-base font-semibold text-gray-500">({subjectLevels.sectionBUSI.minorLevel})</span>
            </div>
            <div className="ml-3 text-xl font-normal text-gray-700 w-full mb-2">{formatMessage(messages.Positives)}</div>
            <div className="ml-3 text-sm text-gray-800 mb-2">
              {formatLevelMessage("Professional", "Pos", subjectLevels.sectionBUSI.majorLevel, subjectLevels.sectionBUSI.minorLevel)}
            </div>
            <div className="ml-3 text-xl font-normal text-gray-700 w-full mb-2">{formatMessage(messages.Improvements)}</div>
            <div className="ml-3 text-sm text-gray-800 mb-2">
              {formatLevelMessage("Professional", "Imp", subjectLevels.sectionBUSI.majorLevel, subjectLevels.sectionBUSI.minorLevel)}
            </div>
          </>
        )}
        {/* STRUCTURE */}
        {!!subjectLevels.sectionSTRU && (
          <>
            <div className="text-xl font-semibold text-gray-800 w-full mb-2 mt-12">
              {formatMessage(messages.StructureTitle)} {subjectLevels.sectionSTRU.majorLevel}
              <span className="text-base font-semibold text-gray-500">({subjectLevels.sectionSTRU.minorLevel})</span>
            </div>
            {result?.recommendationsstru && (
              <>
                <div className="ml-3 text-xl font-normal text-gray-700 w-full mb-2">{formatMessage(messages.Structure)}</div>
                <div className="ml-3 text-sm text-gray-800 mb-2">{result.recommendationsstru}</div>
              </>
            )}
          </>
        )}

        <div className="flex flex-row items-end mt-12">
          <img
            className="p-0 m-0 h-16 cursor-pointer"
            style={{ display: "block", maxWidth: 150, maxHeight: 150, width: "auto", height: "auto" }}
            src={logo}
            alt="atolo"
          />
          <div className="flex flex-1 justify-center items-center mb-8 mr-5 ml-5 hidden-print">
            {result?.letype?.code !== EvaluationType.EASY && <FaPrint className="cursor-pointer" onClick={openSelectPrintModal} />}
            {result?.letype?.code === EvaluationType.EASY && <FaPrint className="cursor-pointer" onClick={() => window?.print()} />}
          </div>

          <div className="flex flex-col justify-center mb-8">
            <a href="https://www.atolo.eu" className="text-sm font-semibold text-gray-500 text-right ">
              www.atolo.eu
            </a>
            <a href="https://www.atolo.ch" className="text-sm font-semibold text-gray-500 text-right ">
              www.atolo.ch
            </a>
          </div>
        </div>
      </div>
      <SelectPrint
        visible={selectPrintModalVisible}
        onClose={closeSelectPrintModal}
        token={result.token}
        reportState={{
          majorLevel,
          minorLevel,
          name,
          languageTested,
          languageLevel,
          duration,
          finishedTimestamp: result.finishedTimestamp,
          subjectLevels,
          languagePercentage,
          resultObject: result,
        }}
      />
    </>
  )
}

export default Final
