import { getLevelEvaluationByToken } from "src/api"

export const setLevelEvaluationByToken = (levelEvaluationToken, levelEvaluation) => ({
  type: "levelEvaluationByToken/SET_LEVEL_EVALUATION",
  levelEvaluationToken,
  payload: levelEvaluation,
})

export const setLoading = (levelEvaluationToken, loading) => ({
  type: "levelEvaluationByToken/SET_LOADING",
  levelEvaluationToken,
  payload: loading,
})

export const setError = (levelEvaluationToken, error) => ({
  type: "levelEvaluationByToken/SET_ERROR",
  levelEvaluationToken,
  payload: error,
})

export const fetchLevelEvaluationByToken = levelEvaluationToken => async dispatch => {
  dispatch(setLoading(levelEvaluationToken, true))
  try {
    const levelEvaluation = await getLevelEvaluationByToken(levelEvaluationToken)
    dispatch(setLevelEvaluationByToken(levelEvaluationToken, levelEvaluation))
  } catch (error) {
    dispatch(setError(levelEvaluationToken, error))
  } finally {
    dispatch(setLoading(levelEvaluationToken, false))
  }
}
