import { useState } from "react"
import { provideAnswer } from "src/api"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const execute = async (evaluationQuestionUUID, answerText, timeToAnswer) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("ANSWER_QUESTION"))

      setLoading(true)
      setError(undefined)
      const data = await provideAnswer(evaluationQuestionUUID, answerText, timeToAnswer)
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }
  return { loading, error, provideAnswer: execute }
}
