import { getLevelEvaluationByUUID } from "src/api"
import { onLogout } from "../account/actions"

export const setLevelEvaluation = (levelEvaluationUUID, levelEvaluation) => ({
  type: "levelEvaluation/SET_LEVEL_EVALUATION",
  levelEvaluationUUID,
  payload: levelEvaluation,
})

export const setLoading = (levelEvaluationUUID, loading) => ({
  type: "levelEvaluation/SET_LOADING",
  levelEvaluationUUID,
  payload: loading,
})

export const setError = (levelEvaluationUUID, error) => ({
  type: "levelEvaluation/SET_ERROR",
  levelEvaluationUUID,
  payload: error,
})

export const fetchLevelEvaluation = levelEvaluationUUID => async dispatch => {
  dispatch(setLoading(levelEvaluationUUID, true))
  try {
    const levelEvaluation = await getLevelEvaluationByUUID(levelEvaluationUUID)
    dispatch(setLevelEvaluation(levelEvaluationUUID, levelEvaluation))
  } catch (error) {
    dispatch(setError(levelEvaluationUUID, error))
    if ((error.code = "ForbiddenAccess" && error.message === "No valid authentication")) {
      dispatch(onLogout())
    }
  } finally {
    dispatch(setLoading(levelEvaluationUUID, false))
  }
}
