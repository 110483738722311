import React, { useState, useEffect } from "react"
import useTimer from "../../../../utils/hooks/useTimer"
import TextInput from "../../../base/forms/textInput"
import Subtitle from "../../../layout/subtitle"
import Button from "../../../base/buttons/regular"
import Timer from "./timer"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import notifications from "../../../../utils/notifications"
import { defaultMessages } from "../../../../utils/defaultStaticStrings"

const messages = defineMessages({
  continueButton: "Continue",
  dissabledContinueButtonTooltip: "Please chose an answer!",
  timerHasEndedNotificationTitle: "The time has ended",
  timerHasEndedNotificationMessage: "You are redirected to a new question",
})

const mockQuestion = {
  uuid: "test123",
  step: 3,
  levelevaluationuuid: "test123",
  questiontext: ["Gisteren ben ik", "naar de bakker", "gegaan"], // Here the question text is already split up, this should be changed once the notation is clear.
  instructiontext: "Fill in the missing word",
}

const OpenQuestion = ({ question = mockQuestion, provideAnswer }) => {
  const TIMER_MAX = 41
  const { seconds } = useTimer(TIMER_MAX)

  const formatMessage = useFormatMessage()

  const [answer, setAnswer] = useState("")
  const [buttonDisabled, setbuttonDisabled] = useState(true)

  useEffect(() => {
    setTimeout(() => setbuttonDisabled(false), 2000)
  }, [question])

  const handleTimerHasEnded = () => {
    notifications.info(formatMessage(messages.timerHasEndedNotificationTitle), formatMessage(messages.timerHasEndedNotificationMessage))
    provideAnswer("", TIMER_MAX * 1000)
  }
  let instrutcionText
  if (question.questiondata.instructionid) {
    instrutcionText = formatMessage(defaultMessages[question.questiondata.instructionid])
  } else if (question.questiondata.instruction) {
    instrutcionText = question.questiondata.instruction
  }

  return (
    <div className={` flex flex-col items-center justify-center`}>
      <div className={`w-full flex  ${seconds < 31 ? "visible" : "invisible"}`}>
        <div className="flex flex-1" />
        <Timer onTimerEnded={handleTimerHasEnded} isPlaying={seconds < 31} />
      </div>{" "}
      <Subtitle className="mt-5">{instrutcionText}</Subtitle>
      <form
        className={`flex flex-col items-center justify-center`}
        onSubmit={e => {
          e.preventDefault()
          provideAnswer(answer, (TIMER_MAX - seconds) * 1000)
          setAnswer()
        }}
      >
        <div className="flex flex-col items-center mt-12">
          <span>{question.questiondata.question}</span>
          {question.questiondata.question2 && <span>{question.questiondata.question2}</span>}
          <span className="my-2">{question.questiondata.hint}</span>
        </div>
        <TextInput autoFocus className="w-48" placeholder={question.questiondata.help} value={answer} onChange={e => setAnswer(e.target.value)} />
        <Button type="submit" className="mt-10" primary disabled={buttonDisabled} tooltip={formatMessage(messages.dissabledContinueButtonTooltip)}>
          {formatMessage(messages.continueButton)}
        </Button>
      </form>
    </div>
  )
}

export default OpenQuestion
