import { useDispatch } from "react-redux"
import { useEffect } from "react"

export const useFetching = (action, trigger) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(action)
  }, [trigger])
}
