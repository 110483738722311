import React from "react"
import { CountdownCircleTimer } from "react-countdown-circle-timer"

const Timer = ({ onTimerEnded, isPlaying }) => {
  return (
    <CountdownCircleTimer
      size={40}
      strokeWidth={3}
      isPlaying={isPlaying}
      durationSeconds={30}
      colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
      onComplete={onTimerEnded}
      renderTime={num => num}
    />
  )
}

export default Timer
