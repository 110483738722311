import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { useFetching } from "../utils/hooks/useFetch"
import useProvideAnswer from "../utils/hooks/useProvideAnswer"
import Phase from "../components/test/phase"
import Question from "../components/test/question"
import { useSelector } from "react-redux"
import notifications from "../utils/notifications"
import queryString from "query-string"
import { fetchLevelEvaluationByToken } from "../store/levelEvaluationByToken/actions"
import { levelEvaluationByTokenSelector } from "../store/levelEvaluationByToken/selectors"
import { Helmet } from "react-helmet"
import { getEvaluationQuestion } from "src/api"
import { Confetti } from "../components/base/Confetti"
import { EvaluationType } from "src/api/types"

const LevelEvaluationPage = () => {
  const [question, setQuestion] = useState()
  const [showNewPhase, setShowNewPhase] = useState(false)

  const search = queryString.parse(window.location.search)
  const levelEvaluationToken = search?.token

  useFetching(fetchLevelEvaluationByToken(levelEvaluationToken), levelEvaluationToken)

  const { result: levelEvaluation } = useSelector(levelEvaluationByTokenSelector(levelEvaluationToken))
  const { provideAnswer, loading: answerLoading } = useProvideAnswer()

  const fetchInitialQuestion = async () => {
    try {
      const question = await getEvaluationQuestion(levelEvaluationToken)
      setNewQuestion(question)
    } catch (error) {
      notifications.error("Oops! Something went wrong.", error.message, error.details)
    }
  }

  useEffect(() => {
    if (levelEvaluationToken) {
      fetchInitialQuestion()
    } else {
      window.location.href = "https://www.atolo.eu"
    }
  }, [levelEvaluationToken])

  const setNewQuestion = question => {
    setShowNewPhase(question?.displayScreen?.length > 0 && question?.displayScreen !== "finished")
    setQuestion(question)
  }

  const answerQuestion = async (answer, timeToAnswer = 0) => {
    try {
      if (!question) {
        throw Error("Oops something went wrong")
      }
      const data = await provideAnswer(question.uuid, answer, timeToAnswer)
      setNewQuestion(data)
    } catch (error) {
      notifications.error("Oops! Something went wrong.", error.message, error.details)
    }
  }

  const showConfetti = showNewPhase && question?.step > 3

  const structureRep = levelEvaluation?.letype?.structure
  const structure = typeof structureRep === "string" ? JSON.parse(structureRep) : structureRep
  const maxQuestions = structure?.maxquestions ?? levelEvaluation?.maximumsteps

  const shouldSkipPhaseScreen = levelEvaluation?.letypecode === EvaluationType.EASY && question?.step < 3

  return (
    <Layout type="hr" noHeaderOptions>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Evaluation</title>
      </Helmet>
      <Confetti show={showConfetti} />
      {showNewPhase && !shouldSkipPhaseScreen && (
        <Phase phase={question?.displayScreen} onNext={() => setShowNewPhase(false)} lvltype={levelEvaluation?.letypecode} />
      )}
      {(!showNewPhase || shouldSkipPhaseScreen) && (
        <Question question={question} nbOfSteps={maxQuestions} provideAnswer={answerQuestion} loading={answerLoading || !question} />
      )}
    </Layout>
  )
}

export default LevelEvaluationPage
