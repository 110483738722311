import React, { useState } from "react"
import useTimer from "src/utils/hooks/useTimer"
import Timer from "src/components/test/question/components/timer"
import _ from "lodash"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "src/utils/hooks/useFormatMessage"
import notifications from "src/utils/notifications"
import { useThemeColors } from "src/utils/hooks/useThemeColors"
import Button from "src/components/base/buttons/regular"

const m = defineMessages({
  submitButtonText: "Submit",
  timerHasEndedNotificationTitle: "The time has ended",
  timerHasEndedNotificationMessage: "You are redirected to a new question",
  singleVisualQuestionHint: "What is shown on this image?",
})

type Props = {
  question?: string
  secondaryQuestion?: string
  hint?: string
  instruction?: string
  image: string
  suggestions: string[]
  answer: (answer: string, time: number) => void
}

const MAX_TIMER_DURATION_IN_SECONDS = 41

const shuffle = _.memoize(suggestions => {
  if (!suggestions) return []
  return _.shuffle(suggestions)
})

export const SingleVisualQuestion: React.FC<Props> = props => {
  const { question, secondaryQuestion, hint, instruction, image, suggestions, answer } = props

  const [selected, setSelected] = useState("")

  const t = useFormatMessage()
  const { seconds } = useTimer()

  const { regular } = useThemeColors()

  const shuffledSuggestions = shuffle(suggestions)

  return (
    <div className={`flex flex-col items-center justify-center`}>
      <div className="flex w-full relative">
        <div className={`w-full flex absolute  ${seconds < 31 ? "visible" : "invisible"}`}>
          <div className="flex flex-1" />
          <Timer
            isPlaying={seconds < 31}
            onTimerEnded={() => {
              notifications.info(t(m.timerHasEndedNotificationTitle), t(m.timerHasEndedNotificationMessage))
              answer(selected, MAX_TIMER_DURATION_IN_SECONDS * 1000)
            }}
          />
        </div>
      </div>
      <div className="flex flex-col items-center space-y-1">
        {instruction && <span className="font-semibold text-lg">{instruction}</span>}
        {question && <span className="font-medium">{question}</span>}
        {secondaryQuestion && <span className="text-sm">{secondaryQuestion}</span>}
        {hint && <span className="text-sm">{hint}</span>}
      </div>
      <img key={image} className="m-8 w-64 h-40 border-2 border-transparent rounded overflow-hidden" src={image} />
      <div className="mt-2 flex items-center pb-4 justify-center flex-wrap">
        {shuffledSuggestions.map(suggestion => (
          <div
            className={`m-1 p-2 px-4 border-2 ${
              selected === suggestion ? `border-${regular}` : "border-gray-400"
            } hover:border-${regular} cursor-pointer rounded overflow-hidden uppercase text-sm font-medium`}
            key={suggestion}
            onClick={() => {
              setSelected(suggestion)
            }}
          >
            {suggestion}
          </div>
        ))}
      </div>
      {selected && (
        <Button
          primary
          className="text-sm"
          onClick={() => {
            answer(selected, (MAX_TIMER_DURATION_IN_SECONDS - seconds) * 1000)
          }}
        >
          {t(m.submitButtonText)}
        </Button>
      )}
    </div>
  )
}
