import _ from "lodash"
import { EvaluationType } from "src/api/types"

export const levels = [
  "A0 (0)",
  "A0 (25)",
  "A0 (50)",
  "A0 (75)",
  "A1 (0)",
  "A1 (25)",
  "A1 (50)",
  "A1 (75)",
  "A2 (0)",
  "A2 (25)",
  "A2 (50)",
  "A2 (75)",
  "B1 (0)",
  "B1 (25)",
  "B1 (50)",
  "B1 (75)",
  "B2 (0)",
  "B2 (25)",
  "B2 (50)",
  "B2 (75)",
  "C1 (0)",
  "C1 (25)",
  "C1 (50)",
  "C1 (75)",
  "C2 (0)",
  "C2 (25)",
  "C2 (50)",
  "C2 (75)",
]

export const simpleLevels = [
  "A0 (0)",
  "A0 (25)",
  "A0 (50)",
  "A0 (75)",
  "A1 (0)",
  "A1 (25)",
  "A1 (50)",
  "A1 (75)",
  "A2 (0)",
  "A2 (25)",
  "A2 (50)",
  "A2 (75)",
  "B1 (0)",
  "B1 (25)",
  "B1 (50)",
  "B1 (75)",
]

export const getLevelForPercentage = (score, testType = EvaluationType.STANDARD) => {
  const availableLevels = testType === EvaluationType.EASY ? simpleLevels : levels

  if (1 >= parseFloat(score) && availableLevels.indexOf(availableLevels[availableLevels.length - 1]) / availableLevels.length < parseFloat(score)) {
    return availableLevels[availableLevels.indexOf(availableLevels[availableLevels.length - 1])]
  }
  return availableLevels.filter(level => {
    return (
      availableLevels.indexOf(level) / availableLevels.length >= parseFloat(score) &&
      (availableLevels.indexOf(level) - 1) / availableLevels.length < parseFloat(score)
    )
  })[0]
}

const combineScoresInDetail = scoresindetail => {
  const parsedScoresInDetail = {}
  _.forOwn(scoresindetail, (value, level) => {
    if (level === "domainAG" || level === "domainPG") {
      parsedScoresInDetail["General"] = parsedScoresInDetail["General"]
        ? (parsedScoresInDetail["General"] + parseFloat(value)) / 2
        : parseFloat(value)
    } else if (level === "domainAB" || level === "domainPB") {
      parsedScoresInDetail["Business"] = parsedScoresInDetail["Business"]
        ? (parsedScoresInDetail["Business"] + parseFloat(value)) / 2
        : parseFloat(value)
    } else {
      parsedScoresInDetail[level] = value
    }
  })
  return parsedScoresInDetail
}
