import { useState, useEffect, useCallback } from "react"

// 41 seconds is the default max
export default (endingTime = 41, handleTimerEnded) => {
  const [seconds, setSeconds] = useState(endingTime)

  useEffect(() => {
    if (seconds <= 0) {
      if (handleTimerEnded) handleTimerEnded()
      return
    }

    const interval = setInterval(() => setSeconds(seconds - 1), 1000)

    return () => clearInterval(interval)
  }, [seconds])

  const increase = useCallback(
    increment => {
      if (setSeconds) {
        setSeconds(seconds + increment)
      }
    },
    [seconds, setSeconds]
  )

  return { seconds, increase }
}
