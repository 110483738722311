import React from "react"
import _ from "lodash"
import { levels, simpleLevels } from "./utils/levels"
import { useThemeColors } from "src/utils/hooks/useThemeColors"
import { ChevronRightIcon } from "@heroicons/react/solid"

const REGULAR_SCALE = ["A0 (0)", "A1 (0)", "A2 (0)", "B1 (0)", "B2 (0)", "C1 (0)", "C2 (0)"]
const SIMPLE_SCALE = ["A0 (0)", "A1 (0)", "A2 (0)", "B1 (0)", "B2 (0)"]

const LevelScale = ({ majorLevel, minorLevel, languagePercentage, className, isSimple = false }) => {
  const scale = isSimple ? SIMPLE_SCALE : REGULAR_SCALE
  const usedLevelsOnScale = isSimple ? simpleLevels : levels

  const MAX_BORDER = isSimple ? 95 : 80

  const nbOfLevels = usedLevelsOnScale.length
  const percentage = languagePercentage / 100
  const overBorder = Math.min(percentage + 0.01, 1) * 100 >= MAX_BORDER
  const { regular } = useThemeColors()
  return (
    <div className={`w-full flex ${className}`}>
      <div className={`flex w-full justify-between`}>
        <div className={`h-2 bg-gray-300 ${isSimple ? "rounded-l" : "rouded"} w-full flex`} style={{ position: "relative" }}>
          <div
            className="flex flex-col items-center absolute font-xs text-gray-800 -mt-12 -ml-8"
            style={{ left: `${overBorder ? MAX_BORDER : Math.min(percentage + 0.01, 1) * 100}%`, zIndex: -10 }}
          >
            <div className="mt-4 font-bold">
              {majorLevel}
              {minorLevel && <span className="text-xs text-gray-500">{`(${minorLevel?.replace(/[{()}]/g, "")})`}</span>}
              <span className="text-xs text-gray-700 ml-1">({languagePercentage}%)</span>
              {isSimple && majorLevel === "B2" && languagePercentage === 100 && <span className="ml-1">&rsaquo;</span>}
            </div>
          </div>
          <div className={`bg-${regular} h-2 ${isSimple ? "rounded-l" : "rounded"}`} style={{ width: `${Math.min(percentage + 0.01, 1) * 100}%` }} />

          {scale.map(l => (
            <div
              key={l}
              className="flex flex-col items-center absolute font-xs text-gray-500 mt-2 -ml-2"
              style={{ left: `${Math.min(levels.indexOf(l) / nbOfLevels + 0.01, 0.99) * 100}%`, zIndex: -10 }}
            >
              <div className="bg-gray-400 -mt-1" style={{ height: 10, width: 2 }}></div>
              {_.first(l.split(" "))}
            </div>
          ))}
        </div>
      </div>
      {isSimple && (
        <div className="w-32">
          <div className="h-2 bg-gray-200 border-dashed border-2 border-gray-400 rounded-r w-full flex justify-end" style={{ position: "relative" }}>
            <div className="flex flex-col items-center absolute font-xs text-gray-500 mt-2 z-0">
              <div className="bg-gray-400" style={{ height: 5, width: 2 }}></div>
              C2
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LevelScale
