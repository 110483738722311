import React from "react"
import useWindowSize from "react-use/lib/useWindowSize"
import ReactConfetti from "react-confetti"

type Props = {
  show?: boolean
}

export const Confetti: React.FC<Props> = props => {
  const { show } = props
  const { width, height } = useWindowSize()

  if (!show) {
    return null
  }

  return <ReactConfetti width={width} height={height} recycle={false} />
}
