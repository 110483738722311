import React, { useState, useEffect } from "react"
import useTimer from "../../../../../utils/hooks/useTimer"
import Subtitle from "../../../../layout/subtitle"
import Button from "../../../../base/buttons/regular"
import Option from "./components/option"
import Timer from "../timer"
import _ from "lodash"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../../utils/hooks/useFormatMessage"
import notifications from "../../../../../utils/notifications"
import { defaultMessages } from "../../../../../utils/defaultStaticStrings"
import { TiMicrophoneOutline } from "react-icons/ti"
import Sound from "react-sound"
import { FaWifi } from "react-icons/fa"
import { VolumeUpIcon } from "@heroicons/react/solid"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  continueButton: "Continue",
  dissabledContinueButtonTooltip: "Please chose an answer!",
  timerHasEndedNotificationTitle: "The time has ended",
  timerHasEndedNotificationMessage: "You are redirected to a new question",
  NONE_OF_THE_ABOVE: "None of the above",
  listenToFragment: "First listen to the fragment.",
  play: "Play",
  playAgain: "Play again",
  pause: "Pause",
})

const filterSuggestions = _.memoize(suggestions => {
  if (!suggestions) return []
  return _.shuffle(suggestions.filter(s => s !== "NONE_OF_THE_ABOVE"))
})

const filterNoneOfTheAboveSuggestions = suggestions => {
  if (!suggestions) return []
  return suggestions.filter(s => s === "NONE_OF_THE_ABOVE")
}

const MultipleChoiceQuestion = ({ question, provideAnswer, audioURL }) => {
  const TIMER_MAX = !!audioURL ? 200000 : 41
  const { seconds } = useTimer(TIMER_MAX)
  const [playing, setPlaying] = useState(false)
  const [soundLoaded, setSoundLoaded] = useState(false)
  const [onFirstPlay, setOnFirstPlay] = useState(!!audioURL && question.displayScreen === "audio")

  const { regular, highlight } = useThemeColors()

  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    if (question.displayScreen !== "audio") {
      setOnFirstPlay(false)
    } else if (!!audioURL && question.displayScreen === "audio") {
      setSoundLoaded(false)
      setOnFirstPlay(true)
    }

    setTimeout(() => setButtonDisabled(false), 1500)
  }, [question])

  const formatMessage = useFormatMessage()

  const handleTimerHasEnded = () => {
    notifications.info(formatMessage(messages.timerHasEndedNotificationTitle), formatMessage(messages.timerHasEndedNotificationMessage))
    provideAnswer("", TIMER_MAX * 1000)
  }

  const [answer, setAnswer] = useState()

  let hasColoredWord
  if (question && question.questiondata && question.questiondata.question) {
    hasColoredWord = `${question.questiondata.question}`.search(/\*(.*?)\*/) >= 0
  }

  let questionInArray
  let coloredWord
  let indexOfColoredWord
  if (hasColoredWord) {
    questionInArray = question.questiondata.question.split(/\*(.*?)\*/).filter(e => !!e)
    coloredWord = questionInArray[1]
    indexOfColoredWord = _.findIndex(questionInArray, word => word === coloredWord)
  }

  let instructionText
  if (question.questiondata.instructionid) {
    instructionText = formatMessage(defaultMessages[question.questiondata.instructionid])
  } else if (question.questiondata.instruction) {
    instructionText = question.questiondata.instruction
  }

  const hint = question?.questiondata?.hint

  if (onFirstPlay) {
    return (
      <div className={`flex flex-col items-center justify-center`}>
        <span className={`px-1 text-${regular} font-bold mt-4`}>{formatMessage(messages.listenToFragment)}</span>
        <div className="flex space-x-2 mt-4">
          <Button className="text-sm" secondary onClick={() => setPlaying(!playing)} tooltip={formatMessage(messages.dissabledContinueButtonTooltip)}>
            {playing ? formatMessage(messages.pause) : formatMessage(messages.play)}
            <VolumeUpIcon className={`h-5 w-5 ml-2 ${playing ? `text-${regular}` : "text-gray-600"}`} />
            <Sound url={audioURL} autoLoad={true} playStatus={playing ? "PLAYING" : "STOPPED"} />
          </Button>
          <Button primary className="text-sm" secondary onClick={() => setOnFirstPlay(false)}>
            {formatMessage(messages.continueButton)}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={`flex flex-col items-center justify-center`}>
      <div className="flex w-full">
        <div className={`w-full flex  ${seconds < 31 ? "visible" : "invisible"}`}>
          <div className="flex flex-1" />
          <Timer seconds={seconds} onTimerEnded={() => handleTimerHasEnded()} isPlaying={seconds < 31} />
        </div>
      </div>

      <Subtitle className="text-center">
        {hasColoredWord ? (
          questionInArray.map((part, i) => {
            if (i === indexOfColoredWord) {
              return <span className={`text-${highlight}`}>{part}</span>
            } else {
              return <span>{part}</span>
            }
          })
        ) : (
          <span>{question.questiondata.question}</span>
        )}
      </Subtitle>
      {hint && <span className="text-sm">{hint}</span>}
      <div className="flex items-center">
        {instructionText &&
          instructionText !== "<blank>" &&
          (Array.isArray(instructionText) ? (
            instructionText.map(text => (
              <span key={text} className={`px-1 text-${regular} font-bold`}>
                {text}
              </span>
            ))
          ) : (
            <span className={`px-1 mt-5 text-${regular} font-bold`}>{instructionText}</span>
          ))}
      </div>
      <div className="flex w-full items-center md:px-8">
        <div className="w-full h-full flex  justify-center">
          <div className="mt-5 flex flex-col ">
            {filterSuggestions(question.questiondata.suggestions).map(suggestion => (
              <Option key={suggestion} text={suggestion} selected={suggestion === answer} onClick={() => setAnswer(suggestion)} />
            ))}
            {filterNoneOfTheAboveSuggestions(question.questiondata.suggestions).map(suggestion => {
              return (
                <Option
                  key={suggestion}
                  text={formatMessage(messages[suggestion])}
                  selected={suggestion === answer}
                  onClick={() => setAnswer(suggestion)}
                />
              )
            })}
          </div>
        </div>
      </div>

      <div className="flex space-x-2 mt-6">
        {!!audioURL && (
          <div className="flex space-x-2">
            <Button
              className="text-sm"
              secondary
              onClick={() => setPlaying(!playing)}
              tooltip={formatMessage(messages.dissabledContinueButtonTooltip)}
            >
              {playing ? formatMessage(messages.pause) : formatMessage(messages.playAgain)}
              <VolumeUpIcon className={`h-5 w-5 ml-2 ${playing ? `text-${regular}` : "text-gray-500"}`} />
              <Sound url={audioURL} autoLoad={true} playStatus={playing ? "PLAYING" : "STOPPED"} />
            </Button>
          </div>
        )}
        <Button
          className="text-sm"
          primary
          onClick={() => {
            provideAnswer(answer, (TIMER_MAX - seconds) * 1000)
            setAnswer()
            setPlaying(false)
            setOnFirstPlay(false)
          }}
          disabled={buttonDisabled}
          tooltip={formatMessage(messages.dissabledContinueButtonTooltip)}
        >
          {formatMessage(messages.continueButton)}
        </Button>
      </div>
    </div>
  )
}

export default MultipleChoiceQuestion
