import React from "react"
import { defaultMessages } from "src/utils/defaultStaticStrings"
import useFormatMessage from "src/utils/hooks/useFormatMessage"

const msgs: any = defaultMessages

type Formatter = (...args: any[]) => string & (string | React.ReactNodeArray)

export const getInstructionText = (f: Formatter, instruction?: string): string | null => {
  if (f && instruction) {
    return f(msgs[instruction])
  }

  if (instruction && instruction !== "<blank>") {
    return instruction
  }

  return null
}

export const useInstructionText = (instruction?: string): string | null => {
  const f = useFormatMessage()
  return getInstructionText(f, instruction)
}
