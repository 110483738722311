import React from "react"
import Radio from "../../../../../base/forms/radio"

const Option = ({ selected, text, onClick }) => {
  return (
    <div className="flex cursor-pointer" onClick={onClick}>
      <div className="flex justify-start items-center md:2/3">
        <Radio selected={selected} />
        <p className="ml-5">{text}</p>
      </div>
    </div>
  )
}

export default Option
