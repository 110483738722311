import React, { useState } from "react"
import Loader from "../../base/loaders/loader"
import Modal from "../../layout/modal"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import OpenQuestion from "./components/openQuestion"
import MultipleChoiceQuestion from "./components/multipleChoiceQuestion"
import Final from "./components/final"
import { MultipleVisualQuestion } from "./components/MultipleVisualQuestion"
import { SingleVisualQuestion } from "./components/SingleVisualQuestion"
import { MultipleChoiceAudioQuestion } from "./components/MultipleChoiceAudioQuestion"
import { useInstructionText } from "./components/utils"

// TODO: center questions in mobile view. Issue: h-screen does not include header, as such centering results in content being displayed to low.

const Question = ({ question, provideAnswer, loading, nbOfSteps }) => {
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")

  const currentSize = useWindowSize()

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && widthOfModal !== "w-full") {
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && widthOfModal !== "w-3/5") {
    setWidthOfModal("w-3/5")
  }

  const instructionTranslated = useInstructionText(question?.questiondata?.instructionid)

  const isFinalResponse = question && question.questiondata.questiontype === 999

  const showQuestion = question && !loading

  const question1 = question?.questiondata?.question
  const question2 = question?.questiondata?.question2
  const hint = question?.questiondata?.hint
  const instruction = question?.questiondata?.instructionid ? instructionTranslated : question?.questiondata?.instruction

  return (
    <Modal noTitle mdWidth={widthOfModal} className="container" greenLinePercentage={question ? question.step / nbOfSteps : 0} greenLineHeight={2}>
      {loading && <Loader />}
      {question && isFinalResponse && !loading && <Final levelEvaluationUUId={question.levelevaluationuuid} />}
      {(showQuestion &&
        (((question.questiondata.questiontype === 1 || question.questiondata.questiontype === 4) && (
          <MultipleChoiceQuestion
            key={question?.uuid}
            inMobile={["sm", "md"].includes(currentSize)}
            question={question}
            provideAnswer={provideAnswer}
          />
        )) ||
          ((question.questiondata.questiontype === 2 || question.questiondata.questiontype === 3) && (
            <OpenQuestion key={question?.uuid} inMobile={["sm", "md"].includes(currentSize)} question={question} provideAnswer={provideAnswer} />
          )) ||
          (question.questiondata.questiontype === 5 && (
            <MultipleChoiceQuestion
              key={question?.uuid}
              inMobile={["sm", "md"].includes(currentSize)}
              question={question}
              provideAnswer={provideAnswer}
              audioURL={question.questiondata.audiofile}
            />
          )) ||
          (question?.questiondata?.questiontype === 6 && (
            <SingleVisualQuestion
              key={question?.uuid}
              question={question1}
              secondaryQuestion={question2}
              hint={hint}
              instruction={instruction}
              image={question?.questiondata?.image}
              suggestions={question?.questiondata?.suggestions ?? []}
              answer={(answer, time) => provideAnswer(answer, time)}
            />
          )) ||
          (question?.questiondata?.questiontype === 7 && (
            <MultipleVisualQuestion
              key={question?.uuid}
              question={question1}
              secondaryQuestion={question2}
              hint={hint}
              instruction={instruction}
              suggestions={question?.questiondata?.suggestions ?? []}
              answer={(answer, time) => provideAnswer(answer, time)}
            />
          )))) ||
        (question?.questiondata?.questiontype === 8 && (
          <MultipleChoiceAudioQuestion
            key={question?.uuid}
            question={question1}
            secondaryQuestion={question2}
            hint={hint}
            instruction={instruction}
            audio={question?.questiondata?.audiofile}
            suggestions={question?.questiondata?.suggestions ?? []}
            answer={(answer, time) => provideAnswer(answer, time)}
          />
        )) ||
        (question?.questiondata?.questiontype === 9 && (
          <MultipleChoiceAudioQuestion
            key={question?.uuid}
            question={question1}
            secondaryQuestion={question2}
            hint={hint}
            instruction={instruction}
            audio={question?.questiondata?.audiofile}
            suggestions={question?.questiondata?.suggestions ?? []}
            answer={(answer, time) => provideAnswer(answer, time)}
          />
        ))}
    </Modal>
  )
}

export default Question
