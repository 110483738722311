import React from "react"
import Modal from "../layout/modal"
import Button from "../base/buttons/regular"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  grammarPhaseTitle: "Grammar phase",
  vocabularyPhaseTitle: "Vocabulary phase",
  audioPhaseTitle: "Audio phase",
  grammarPhaseDescription: "Grammar phase",
  vocabularyPhaseDescription: "Vocabulary phase",
  quickPhaseTitle: "Well done!",
  quickPhaseDescription: "This part is finished.",
  audioPhaseDescription: "Audio phase",
  continueButton: "Continue",
})

const Phase = ({ onNext, phase, lvltype }) => {
  const formatMessage = useFormatMessage()

  let type = "grammar"
  if (phase === "voc") {
    type = "vocabulary"
  } else if (phase === "audio") {
    type = "audio"
  }

  if (lvltype === EvaluationType.EASY) {
    type = "quick"
  }

  return (
    <Modal title={formatMessage(messages[type + "PhaseTitle"])} navigation={true} lgWidth="w-2/5">
      <div className="text-sm text-center max-w-md mx-auto">{formatMessage(messages[type + "PhaseDescription"])}</div>
      <div className="flex  justify-center">
        <Button primary className="w-2/5 text-center mt-8" onClick={onNext}>
          {formatMessage(messages.continueButton)}
        </Button>
      </div>
    </Modal>
  )
}

export default Phase
