import React from "react"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../../../utils/hooks/useFormatMessage"
import ModalContainer from "../../../../../base/modals/modalContainer"
import Button from "../../../../../base/buttons/regular"
import { Link } from "gatsby"

const messages = defineMessages({
  printPdfOrReportMessage: "Do you want to print the certificate or the report?",
  selectCertificateButton: "Certificate",
  selectReportButton: "Report",
})

const SelectPrint = ({ token, reportState, visible, onClose, className }) => {
  const formatMessage = useFormatMessage()

  return (
    <ModalContainer visible={visible} onClose={onClose} className={className}>
      <div className="px-3 -mt-2 mb-1">
        <div className="w-full justify-center mb-3">{formatMessage(messages.printPdfOrReportMessage)}</div>
        <div className="flex justify-around w-full">
          <a data-for="" target="_blank" rel="noopener noreferrer" href={`/certificate?levelevaluation=${token}`} className="mx-3">
            <Button>{formatMessage(messages.selectCertificateButton)}</Button>
          </a>
          <Link to={"/pdf-report"} state={reportState}>
            <Button className="mx-3">{formatMessage(messages.selectReportButton)}</Button>
          </Link>
        </div>
      </div>
    </ModalContainer>
  )
}

export default SelectPrint
