import React from "react"
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const Radio = ({ selected }) => {
  const { regular } = useThemeColors()
  if (selected) {
    return <IoMdRadioButtonOn className={`text-${regular}`} size="1em" />
  } else {
    return <IoMdRadioButtonOff className={`text-${regular}`} size="1em" />
  }
}

export default Radio
